import type { ComponentPropsWithoutRef } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface ControlContextValue {
  labelProps: {
    htmlFor: string;
  };
  inputProps: Pick<ComponentPropsWithoutRef<'input'>, 'aria-invalid' | 'aria-errormessage'> & { id: string };
  feedbackMessageProps: {
    id: string;
  };
  error?: null | string;
}

const ControlContext = createContext<ControlContextValue | null>(null);

interface ControlProviderProps {
  id: string;
  error?: null | string;
  children: React.ReactNode;
}

/** Provides correct attributes for a form control accessibility */
export function ControlProvider({ id, error, children }: ControlProviderProps) {
  // Generate correct attributes for a11y
  const value = useMemo(() => {
    const errorId = `${id}-error`;

    const inputProps: ControlContextValue['inputProps'] = { id };

    if (error) {
      inputProps['aria-invalid'] = true;
      inputProps['aria-errormessage'] = errorId;
    }

    const feedbackMessageProps = { id: errorId };

    return { labelProps: { htmlFor: id }, inputProps, feedbackMessageProps, error };
  }, [id, error]);

  return <ControlContext.Provider value={value}>{children}</ControlContext.Provider>;
}

/** Retrieves correct attributes for a form control accessibility */
export function useControl(): Partial<ControlContextValue> {
  const control = useContext(ControlContext);

  return control ?? {};
}
